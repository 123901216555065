// $(document).ready(function(){
// })

window.onload = function(){
    // accordion.init();
    backtotop.init();
    collapse.init();
    // dropdown.init();
    fixedheader.init();
    search.init();  
    toast.init(); 
}

// $(window).resize(function() {}
$(window).on( "orientationchange", function( event ) {
    fixedheader.init();
});