var fixedheader = {
    init: function(){

        var screenwidth = $(window).outerWidth()
        var headerheight = $('header').outerHeight();
		// var navbarTogglerHeight = $('.navbar-toggler').outerHeight();
		// var navtopHeight = $('.p-nav__top').outerHeight();
		// var totalHeaderheight = -(headerheight - navtopHeight);
		
		$('main').css('margin-top',headerheight);   

		if (screenwidth <= 1199) {     
			$('.navbar .navbar-collapse').css('margin-top',headerheight);    
/* 			$(window).bind('scroll', function () {
				var num = 450;
				if ($(window).scrollTop() > num) {
					$('header').addClass('shrink');
					// $('header').css('margin-top',totalHeaderheight);
				} else {
					$('header').removeClass('shrink');
					// $('header').css('margin-top','inherit');
				}
			}); */
		} 
		else { 
			$('.navbar .navbar-collapse').css('margin-top','inherit');
		}

    }
}